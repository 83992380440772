window.addEventListener("DOMContentLoaded", () => {
  const storage = window.localStorage;
  if (storage.getItem("accept-cookies") === "yes") {
    document.querySelector<HTMLDivElement>("#cookiebanner").style.display = "none";
  } else {
    const button = document.querySelector("#cookiebanner button");
    button.addEventListener("click", () => {
      document.querySelector<HTMLDivElement>("#cookiebanner").style.display = "none";
      if (storage.getItem("accept-cookies") !== "yes") {
        storage.setItem("accept-cookies", "yes");
      }
    });
  }
});